import React from "react"
import FAQ from "../../utils"
import Layout from "../components/layout"
import SEO from "../components/seo"
import questions from "../../webdevelopmentFaq.json"

function Webdevelopment() {
  return (
    <Layout>
      <SEO
        title="Professional Web Developemnt Service | Website Solutions"
        description="Get top-notch Web Development Service tailored to your business needs. We create responsive, fast, and SEO-friendly websites to boost your online presence."
        keywords={['Web Development Service', 'website development', 'custom web solutions', 'professional web design', 'responsive websites']}
      />
      <div style={{ textAlign: "justify" }}>
        <div className="container py-5 web-development-services ">
          <div className="row">
            <div className="col-12 web-development-services ">
              <div className="section-title web-development-services ">
                <h1>Web Design & Development Services</h1>
              </div>
              <p>
                D2i Technology is one of the leading Web Design and Development
                Company in India. D2i Technology has many happy clients in India
                and now we are expanding our web design and development services
                around the world. Our quality, cost effective and timely
                delivery are the key factors that help us to keep our client
                happy and help them in growth of their business.
              </p>
              <p>
                Our experienced team of expert website designers and website
                developers in India has been developing best user experience web
                solutions for our customers through the years. Being one of the
                best Web Designing Company, we ensure that each web application
                has the best user experience on web and mobile both, we focus a
                lot on user experience for ends user. We at first discuss about
                the target audience so that we can offer best experience to our
                end users. Our website deliverables are SEO friendly and WCAG
                compliant. Our web development services are in most of the tech
                stacks like MEAN, MERN, MEVN, Drupal, PHP, Ionic, Cake PHP,
                Magento, CodeIgniter, Laravel, AngularJS, Angular, VueJS,
                NodeJS, ReactJS, ReactNative, WordPress, Joomla, Java, YII, etc.
                which helped us to be in the best Web Development Company
                Services list.
              </p>
              <p>
                Our experienced developers follow industry standards to deliver
                quality products timely. We have an experience in e-learning,
                health domain, e-commerce etc. Our services are not restricted
                to website design and development, we serve our clients end to
                end by doing web designing and development, graphic designing,
                content writing, SEO, SMO, digital marketing, deployment,
                maintenance of existing websites, upgrading existing legacy
                websites, DevOps, testing, accessibility testing.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-1"></div>
            <div className="col-10">
              <img
                src="/assets/img/Web Development Services.jpeg"
                className="container"
                alt="Good idea - Empowering your business with our technology solutions."
              />
            </div>
            <div className="col-1"></div>
          </div>
          <div className="FAQ">{FAQ(questions)}</div>
        </div>
      </div>
    </Layout>
  )
}

export default Webdevelopment
